.header {
  position: fixed;
  top: 0;
  width: 100vw;
  @include myFlex();
  @include FlexOptions(100%, nowrap, row);
  background-image: url("./../../../assets/header-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: flex-end;
  height: 20%;
  color: $c-tawnyPort;
  font-family: $f-raleway;
  font-weight: bolder;
  font-size: pxToRem(28);
  z-index: 99;

  p {
    margin: 2%;
    cursor: pointer;
    height: 10%;
  }

  p:last-of-type {
    margin-right: 5%;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    font-size: pxToRem(22);
    padding-top: 5%;
  }
}

@media only screen and (max-width: 415px) {
  .header {
    font-size: pxToRem(20);
  }
}
