.kontakt {
  @include myFlex();
  @include FlexOptions(100%, wrap, row);
  @include center();
  position: relative;
  text-align: center;
  height: 75vh;
  margin: 0;
  padding: 3%;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(234, 249, 249, 0.67) 0.1%,
    rgba(239, 249, 251, 0.63) 90.1%
  );
  font-family: $f-montserrat;
  @include shadow(5px 5px 10px $c-manatee);
  &__title {
    color: $c-tawnyPort;
    font-family: $f-raleway;
    @include FlexOptions(100%);
    font-size: pxToRem(35);
  }
  &__list {
    list-style: none;
    padding: 0;
    width: 50%;
    li {
      @include myFlex();
      @include center();
      justify-content: flex-start;
      color: $c-mulledWine;
      p {
        margin-left: 5%;
        font-size: pxToRem(22);
      }
    }
  }
  &__link {
    text-decoration: none;
    color: $c-mulledWine;
    @include FlexOptions(100%);
    @include myFlex();
  }
}

@media only screen and (max-width: 1100px) {
  .kontakt__list {
    width: 70%;
  }
}

@media only screen and (max-width: 790px) {
  .kontakt {
    &__title {
      font-size: pxToRem(30);
    }
    &__list li p {
      font-size: pxToRem(20);
    }
  }
}

@media only screen and (max-width: 600px) {
  .kontakt {
    height: auto;
    &__title {
      margin-top: 10%;
    }
    &__list {
      width: 85%;
      li p {
        font-size: pxToRem(16);
      }
    }
  }
}
