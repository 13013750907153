//-------Colors
$c-white: #ffffff;
$c-mystic: #deebed;
$c-sinbad: #acd2d8;
$c-jungleMist: #a8c8cc;
$c-manatee: #97989b;
$c-orientalPink: #bf929b;
$c-hopbush: #d06da4;
$c-tawnyPort: #6d1d48;
$c-mulledWine: #49496f;
$c-waikawaGrey: #5775a8;
$c-buddhaGold: #bc9b0b;

//-------Fonts
$f-raleway: "Raleway", sans-serif;
$f-greatVibes: "Great Vibes", cursive;
$f-bonheur: "Bonheur Royale", cursive;
$f-montserrat: "Montserrat", sans-serif;
$f-eczar: "Eczar", serif;
