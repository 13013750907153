.home__button{
    color: $c-manatee;
    margin: 5%;
    padding: 5%;
    cursor: pointer;
    font-size: pxToRem(18);
    background: transparent;
    border: 1px solid $c-manatee;
    width: 50%;
    transition: all 0.3s;
}
.home__header::before {
    content: '';
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: $c-white;
    border-bottom-color: $c-white;
    transform: scale(0.1, 1);
  }
  .home__button:hover {
    letter-spacing: 2px;
    background-color: $c-mystic;
    transition: all 0.3s;
  }
  .link__home{
    text-decoration: none;
    color: $c-manatee;
    cursor: pointer;
    font-weight: bolder;
}