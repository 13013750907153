.rodo{
    background: linear-gradient(0deg, rgba(164,214,214,1) 0%, rgba(214,238,245,1) 100%);
    padding: 5% 10%;
    font-family: $f-montserrat;
    color: $c-mulledWine;
    &__title{
        margin: 5% 10% 2%;
    }
    &__h2{
        width: 100%;
        margin: 2%;
        display: inline-block;
    }
    &__list{
        margin-left: 2%;
        li{
            margin: 2%;
        }
    }
    &__text{
        margin-left: 1%;
    }
    &__link{
        text-decoration: none;
        color: $c-mulledWine;
        cursor: pointer;
        margin-left: 1%;
    }
}