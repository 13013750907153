@import "./fonts.scss";
@import "./normalize.scss";
@import "./variables.scss";

body {
  overflow-x: hidden;
}
.flex {
  @include myFlex();
}
.col-6 {
  @include FlexOptions(60%);
  margin: 10% 0;
}
.relative {
  position: relative;
}

@media only screen and (max-width: 800px) {
  .wrap {
    @include FlexOptions(100%, wrap);
    margin: 5%;
  }
  .col-12 {
    @include FlexOptions(100%);
  }
}
