.about__profil {
  @include FlexOptions(40%);
  margin: 2% 0 0 10%;
  border: 5px solid $c-white;
  border-radius: 5px;
  @include shadow(5px 5px 10px $c-manatee);
  img {
    width: 100%;
  }
}

.about__img {
  text-align: center;
  img {
    width: 50%;
    border: 5px solid $c-white;
    border-radius: 5px;
    @include shadow(5px 5px 10px $c-manatee);
  }
}

.list__img {
  font-size: 180%;
  color: $c-orientalPink;
  @include center();
}

.kontakt__flowers {
  position: absolute;
  top: 0;
  right: 5%;
  width: 25%;
}

@media only screen and (max-width: 1300px) {
  .about__profil {
    @include center();
  }
}

@media only screen and (max-width: 800px) {
  .about__profil {
    margin: 0 auto;
    img {
      width: 125%;
    }
  }

  .kontakt__flowers {
    width: 40%;
  }
}

@media only screen and (max-width: 600px) {
  .about__profil {
    margin: 0 18% 0;
    img {
      width: 150%;
    }
  }
}

@media only screen and (max-width: 450px) {
  .kontakt__flowers {
    width: 60%;
  }
}

@media only screen and (max-width: 400px) {
  .about__profil {
    margin: 0 10% 0;
    img {
      width: 180%;
    }
  }
}
