@function pxToRem($pxsize, $rembase: 16) {
  @return calc($pxsize / $rembase) + rem;
}

@mixin myFlex() {
  $prefixes: (-webkit-box, -ms-flexbox, -webkit-flex, flex);
  @each $prefix in $prefixes {
    display: $prefix;
  }
}
@mixin FlexOptions($basis: false, $wrap: false, $direction: false) {
  @if $basis {
    -ms-flex-preferred-size: $basis;
    flex-basis: $basis;
  }
  @if $wrap {
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
  }
  @if $direction {
    -ms-flex-direction: $direction;
    flex-direction: $direction;
  }
}
@mixin center() {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  align-self: center;
}
@mixin shadow($props) {
  -webkit-box-shadow: $props;
  box-shadow: $props;
}
