%hr-placeholder {
  border: none;
  border-top: 2px solid $c-buddhaGold;
  margin: 0;
}

.about {
  background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
  @include center();
  @include FlexOptions(100%);
  margin: 0;
  padding: 5%;
  &__text {
    background-image: linear-gradient(
      to top,
      #f3e7e9 0%,
      #e3eeff 99%,
      hsl(216, 100%, 95%) 100%
    );
    font-size: pxToRem(20);
    padding: 3%;
    margin: 5% 10%;
    color: $c-mulledWine;
    text-align: center;
    border-radius: 15px;
    @include shadow(5px 5px 10px $c-manatee);
  }
}
.quote {
  font-family: $f-bonheur;
  color: $c-tawnyPort;
  font-size: pxToRem(40);
  padding: 5% 15%;
  &__different p {
    margin: 0;
  }
  p {
    margin: 2%;
  }
}
.hr {
  width: 70%;
  @extend %hr-placeholder;
  @include center();
  margin: 3% 15%;
}
.hr-top {
  width: 60%;
  float: right;
  @extend %hr-placeholder;
}
.hr-bottom {
  width: 30%;
  float: left;
  @extend %hr-placeholder;
}
.author {
  color: $c-hopbush;
  float: right;
  font-family: $f-raleway;
  font-size: pxToRem(25);
  margin: 0;
  font-weight: bolder;
}
.text {
  color: $c-mulledWine;
  font-family: $f-raleway;
  font-size: pxToRem(20);
  text-indent: 5%;
  font-weight: 600;
  text-align: center;
  border: none;
  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
  padding: 3%;
  border-radius: 15px;
  @include shadow(5px 5px 10px $c-manatee);
  p {
    margin: 0 auto;
  }
}
.col-8 {
  text-indent: 0%;
  width: 60%;
}
.margin-10 {
  margin-top: 10%;
}

@media only screen and (max-width: 800px) {
  .about__text {
    margin-top: 20%;
  }

  .quote {
    font-size: pxToRem(30);
  }
  .author {
    margin-top: 3%;
    font-size: pxToRem(19);
  }
  .text {
    font-size: pxToRem(16);
  }
}

@media only screen and (max-width: 400px) {
  .quote {
    font-size: pxToRem(22);
  }
  .author {
    font-size: pxToRem(18);
  }
  .text {
    font-size: pxToRem(15);
  }
  .about__text {
    font-size: pxToRem(16);
  }
}
