.footer {
  background-color: $c-white;
  color: $c-manatee;
  margin-left: 3%;
  span {
    margin-left: 2%;
  }
}
.link__footer {
  text-decoration: none;
  color: $c-manatee;
  cursor: pointer;
}
