.home {
  @include myFlex();
  @include FlexOptions(100%, wrap, row);
  @include center();
  width: 100vw;
  height: 100vh;
  background-image: url("./../../../assets/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &__header {
    @include FlexOptions(30%, wrap, row);
    font-family: $f-raleway;
    color: $c-mulledWine;
    display: flex;
    @include center();
    padding: 5%;
    position: relative;
    background-color: rgba(255, 255, 255, 0.5);
    border: 5px solid $c-mystic;
    border-radius: 15px;
    text-align: center;
  }
  &__h1 {
    font-weight: bolder;
    font-size: pxToRem(60);
    margin-bottom: 1%;
    font-family: $f-greatVibes;
    color: $c-tawnyPort;
  }
  &__h2 {
    font-weight: bold;
    color: $c-mulledWine;
    font-size: pxToRem(20);
    float: left;
    display: block;
  }
}
.home__hr {
  width: 70%;
  border: none;
  border-top: 1px solid $c-tawnyPort;
  float: left;
  margin: 0;
}
.firm__name {
  text-transform: uppercase;
  font-size: pxToRem(30);
}

@media only screen and (max-width: 1150px) {
  .home__header {
    @include FlexOptions(50%);
  }
}

@media only screen and (max-width: 605px) {
  .home {
    @include FlexOptions(100%, wrap);
    &__header {
      @include FlexOptions(80%);
      margin-bottom: 8%;
    }
  }
}
